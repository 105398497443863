import React from 'react';
import { graphql, Link } from 'gatsby';
import tw from 'twin.macro';
import LandingLayout from '../layouts/landing';
import SEO from '../components/seo';
import 'prismjs/themes/prism-solarizedlight.css';
import 'katex/dist/katex.min.css';
import './blog.css';

const TitleCotainer = tw.div`flex flex-col items-center w-full py-6 md:py-10 bg-gradient-to-br from-purple-500 to-purple-700`;
const TitleInnerContainer = tw.div`max-w-screen-lg px-6 text-xl md:text-3xl text-white font-bold text-center`;
const Container = tw.section`mx-auto px-6 md:px-8 py-4 md:py-8 max-w-screen-md text-sm md:text-base text-justify text-gray-600`;
const ActionContainer = tw.div`flex items-center justify-between mx-auto px-6 py-4 md:py-8 max-w-screen-lg border-b`;
const BackToHome = tw(Link)`text-sm md:text-base hover:text-purple-600 select-none`;
const Classify = tw.div`mb-2 px-2 py-1 text-base md:text-lg text-white bg-black font-bold`;
const AuthorContainer = tw.div`mx-auto px-6 md:px-8 py-8 max-w-screen-md text-sm md:text-base border-t text-gray-400`;
const CTA = tw.a`mx-0.5 text-purple-600 font-semibold hover:underline`;

export default ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <LandingLayout>
      <SEO title={frontmatter.title} />
      <TitleCotainer>
        <TitleInnerContainer>{frontmatter.title}</TitleInnerContainer>
      </TitleCotainer>
      <ActionContainer>
        <BackToHome to="/blog/">← 回到文章列表</BackToHome>
        <Classify>{frontmatter.classify}</Classify>
      </ActionContainer>
      <Container id="blog" dangerouslySetInnerHTML={{ __html: html }} itemProp="articleBody" />
      <AuthorContainer>
        {frontmatter.author} 发布于 {frontmatter.date}；如果有疑问或需要讨论，请
        <CTA href={`mailto:lhzbxx@gmail.com?subject=讨论：${frontmatter.title}`}>发送私信</CTA>
        给我。
      </AuthorContainer>
    </LandingLayout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        author
        classify
        date(formatString: "YYYY 年 MM 月 DD 日")
        title
      }
      html
    }
  }
`;
